import styled from "@emotion/styled"
import StyledFlex, { StyledFlexChild } from "../flex/flex-styles"

const StyledContactForm = styled(StyledFlex)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
`

export const StyledContactFormContent = styled(StyledFlexChild)`
  flex: 300px;

  span {
    font-family: poppins;
    display: block;
    margin-bottom: 0.25em;
  }

  @media (min-width: 759px) {
    flex: 0 0 49%;

    &.block {
      flex: 1 1 100%;
    }

    input {
      margin-right: var(--spacing-xs);
    }
  }
`

export const FormSubmitSuccessful = styled.div`
  text-align: center;

  h1 {
    margin-bottom: 1rem;
  }

  p {
    max-width: 600px;
    margin: 0 auto 2em;
  }
`

export default StyledContactForm
