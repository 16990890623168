import styled from "@emotion/styled"

import StyledFlex from "../flex/flex-styles"

export const StyledContactContainer = styled(StyledFlex)`
  .divider {
    width: 1px;
    height: 100px;
    max-height: 100%;
    background: #ccc;
  }

  p {
    margin-top: 0;
  }

  /* max-width: 20em; */
  width: 130px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  align-items: center;
`

const StyledContact = styled.div`
  text-align: center;

  img {
    width: 20px;
    margin-bottom: 1em;
  }
`

export default StyledContact
