import React from "react"

import StyledContact, { StyledContactContainer } from "./contact-styles"
import Phone from "../../images/icons/phone.svg"

const Contact = () => {
  return (
    <StyledContactContainer>
      <StyledContact>
        <img src={Phone} alt="phone icon" />
        <div className="text-caption">Call</div>
        <p>+2348071156434</p>
      </StyledContact>

      {/* <div className="divider"></div>

      <StyledContact>
        <img src={Phone} alt="phone icon" />
        <div className="text-caption">Call</div>
        <p>+2348166771851</p>
      </StyledContact> */}
    </StyledContactContainer>
  )
}

export default Contact
