import React, { useState } from "react"

import { encode } from "../../util/encode"

import {
  Layout,
  Section,
  Container,
  Input,
  Textarea,
  Button,
  Divider,
  SEO,
} from "../components"
import StyledContactForm, {
  StyledContactFormContent,
  FormSubmitSuccessful,
} from "../components/styles/contact-us"
import Contact from "../components/contact-component/contact-component"

const ContactUs = ({ location }) => {
  const [message, setMessage] = useState("")
  const [name, setName] = useState("")
  const [emailAddress, setEmailAddress] = useState("")
  const [formSubmitted, setFormSubmitted] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()

    console.log({})
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        name,
        emailAddress,
        message,
      }),
    })
      .then(res => {
        alert(JSON.stringify(res))
        console.log(res)
        setFormSubmitted(true)
      })
      .catch(error => alert(error))
  }

  return (
    <Layout
      location={location}
      title="We Would Love To Partner With You."
      subTitle="CONTACT US"
    >
      <SEO
        title="Contact Us"
        description="Reach Lightville through any of our numbers or email addresses"
      />
      <Section>
        <Container width="narrow">
          <p style={{ marginBottom: "2em" }}>
            You can reach us on the numbers below 24/7. You can also send us a
            mail and receive our correspondence within 24 hours
          </p>

          <Contact />
          <Divider />

          {formSubmitted ? (
            <FormSubmitSuccessful>
              <h2>Form Submitted</h2>
              <p>
                Your message was delivered successfully. Someone from our team
                will get to you as soon as possible. Thanks
              </p>
            </FormSubmitSuccessful>
          ) : (
            <form
              onSubmit={handleSubmit}
              name="contact"
              method="POST"
              data-netlify="true"
              netlify-honeypot="bot-field"
              action="/registration-successful"
              required
            >
              <StyledContactForm>
                <StyledContactFormContent>
                  <label>
                    <span>Your Name</span>
                    <Input
                      placeholder="Your Name"
                      type="text"
                      value={name}
                      onChange={e => setName(e.target.value)}
                      required
                      name="name"
                    />
                  </label>
                </StyledContactFormContent>

                <StyledContactFormContent>
                  <label>
                    <span>Email Address</span>
                    <Input
                      placeholder="Your Email Address"
                      type="email"
                      value={emailAddress}
                      onChange={e => setEmailAddress(e.target.value)}
                      required
                      name="emailAddress"
                    />
                  </label>
                </StyledContactFormContent>

                <StyledContactFormContent className="block">
                  <label>
                    <span>Message</span>
                    <Textarea
                      placeholder="Your Message"
                      rows={5}
                      onChange={e => setMessage(e.target.value)}
                      value={message}
                      required
                      name="message"
                    ></Textarea>
                  </label>
                </StyledContactFormContent>

                <Button variant="primary">Send Message</Button>
              </StyledContactForm>
            </form>
          )}
        </Container>
      </Section>
    </Layout>
  )
}

export default ContactUs
